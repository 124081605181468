.career-intro {
    text-align: center;
}

.career-intro header {
    margin: 2vw;
    padding: 2vw;
}

.career-footer {
    background-color: #f2f2f2;
    padding: 2vw;
}

.ftco-section>.container>.row {
    margin: 0px !important;
}

.career-intro h2 {
    color: black;
    font-size: 2.5vw;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.career-intro h4 {
    color: black;
    font-size: 1.5vw;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.career-intro p {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    color: black;
}


#career-perks {
    position: relative;
}

#career-perks .row {
    margin: 0px;
    padding: 0px;
}

#career-perks>.container {
    overflow: hidden;
}

.career-perks-heading {
    padding: 2vw;
    margin: 0px;

}

.career-perks-leftCol {
    padding-top: 10vw;
    padding-bottom: 10vw;
    background-color: #8EC5FC;
    background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
}

.career-perks-heading>header>h2 {
    color: black;
    font-size: 2vw;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.career-perks-heading>header>p {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    color: black;
}


#progress-bar {
    width: 25%;
}

.perks-container {
    padding-top: 5vw;
}

.perks-body {
    width: 60%;
    height: 60vh;
    background-color: white;
    justify-content: center;
    margin: auto;
    text-align: center;
    margin-top: 5vw;
    margin-bottom: 5vw;
}

.perks-img {
    height: auto;
    width: 15%;
    display: block;
    margin: auto;
}

.perks-title {
    color: black;
    font-size: 1.4vw;
    margin: 10px 5px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.perks-desc {
    width: 100%;
    height: 10vw;
    display: block;
    margin: auto;
    color: black;
    font-size: 1vw;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
}

.career-footer {
    color: black;
    text-align: center;
}

.c-f-h>span {
    width: 5vw;
    height: 2px;
    background-color: black;
    color: black;

}

.career-footer>header>h2 {
    font-size: 3vw;
    margin: 10px 5px;
    font-weight: 700;
    color: black;
    font-family: 'Montserrat', sans-serif;
}

.perks-section {
    height: 100vh;
}

.career-perks-heading {
    position: sticky;
    top: 250px;
}



@media only screen and (min-device-width: 1000px) and (max-device-width: 1400px) and (max-device-height:1400px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .career-intro h2 {
        font-size: 3vw;
    }

    .career-intro h4 {
        font-size: 2.5vw;
    }

    .career-intro p {
        font-size: 2vw;
    }

    .career-perks-leftCol {
        padding-top: 40vh;
        padding-bottom: 40vh;
    }

    .career-perks-heading {
        top: 30vh;
    }

    .career-perks-heading>h2 {
        font-size: 3vw;
    }

    .career-perks-heading>p {
        font-size: 1.8vw;
    }

    .perks-container {
        padding-top: 40vh;
        padding-bottom: 30vh;
    }

    .perks-body {
        width: 80%;
        height: 40vh;
    }

    .perks-img {
        width: 25%;
    }

    .perks-title {
        font-size: 2.8vw;
    }

    .perks-desc {
        font-size: 1.8vw;
    }

    .career-footer {
        color: black;
        text-align: center;
    }

    .career-footer>header>h2 {
        font-size: 4vw;
    }

    .footer-desc>p {
        font-size: 2vw;
    }


}



@media only screen and (min-device-width: 700px) and (max-device-width: 1000px) and (max-device-height:1200px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .career-intro h2 {
        font-size: 3vw;
    }

    .career-intro h4 {
        font-size: 2.5vw;
    }

    .career-intro p {
        font-size: 2vw;
    }

    .career-perks-leftCol {
        padding-top: 40vh;
        padding-bottom: 40vh;
    }

    .career-perks-heading {
        top: 30vh;
    }

    .career-perks-heading>h2 {
        font-size: 3vw;
    }

    .career-perks-heading>p {
        font-size: 1.8vw;
    }

    .perks-container {
        padding-top: 40vh;
        padding-bottom: 30vh;
    }

    .perks-body {
        width: 80%;
        height: 40vh;
    }

    .perks-img {
        width: 20%;
    }

    .perks-title {
        font-size: 2.8vw;
    }

    .perks-desc {
        font-size: 1.8vw;
    }

    .career-footer {
        color: black;
        text-align: center;
    }

    .career-footer>header>h2 {
        font-size: 4vw;
    }

    .footer-desc>p {
        font-size: 2vw;
    }


}


@media only screen and (min-device-width: 1000px) and (max-device-width: 1300px) and (max-device-height:900px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .career-perks-leftCol {
        padding-top: 40vh;
        padding-bottom: 40vh;
    }

    .career-perks-heading {
        top: 30vh;
    }

    .perks-container {
        padding-top: 50vh;
        padding-bottom: 30vh;
    }

    .perks-img {
        width: 20%;
    }

    .perks-title {
        font-size: 2.8vw;
    }

    .perks-desc {
        font-size: 1.5vw;
    }
}

@media screen and (max-width:600px) {}


/* phone View */
@media only screen and (min-device-width: 400px) and (max-device-width: 550px) and (-webkit-min-device-pixel-ratio: 3) {
    .career-intro {
        padding-left: 3vw;
        padding-right: 3vw;
    }

    #career-perks .row {
        margin: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .career-intro h2 {
        font-size: 5vw;
    }

    .career-intro h4 {
        font-size: 3.5vw;
    }

    .career-intro p {
        font-size: 3.4vw;
    }

    .career-perks-leftCol {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .career-perks-heading>h2 {
        font-size: 5vw;
    }

    .career-perks-heading>p {
        font-size: 3.5vw;
        color: black;
    }

    .career-perks-heading h3 {
        font-size: 5vw;
    }

    .perks-container {
        padding: 5vh 1vh;
    }

    .perks-body {
        width: 80%;
        height: 40vh;
    }

    .perks-img {
        width: 15%;
    }

    .perks-title {
        font-size: 4vw;
    }

    .perks-desc {
        font-size: 3vw;
    }

    .career-footer {
        background-color: #f2f2f2;
        padding-top: 4vh;
    }

    .career-footer>header>h2 {
        font-size: 5vw;
        padding-bottom: 4vh;
    }

    .footer-desc>p {
        font-size: 3.5vw;
    }
}

@media only screen and (min-device-width: 350px) and (max-device-width: 400px) and (-webkit-min-device-pixel-ratio: 3) {
    .career-intro {
        padding-left: 3vw;
        padding-right: 3vw;
    }

    .career-intro h2 {
        font-size: 5vw;
    }

    .career-intro h4 {
        font-size: 3.5vw;
    }

    .career-intro p {
        font-size: 3.4vw;
    }

    .career-perks-leftCol {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .career-perks-heading>h2 {
        font-size: 5vw;
    }

    .career-perks-heading>p {
        font-size: 3.5vw;
        color: black;
    }

    .career-perks-heading h3 {
        font-size: 5vw;
    }

    .perks-container {
        padding: 5vh 1vh;
    }

    .perks-body {
        width: 80%;
        height: 40vh;
    }

    .perks-img {
        width: 15%;
    }

    .perks-title {
        font-size: 4vw;
    }

    .perks-desc {
        font-size: 3vw;
    }

    .career-footer {
        background-color: #f2f2f2;
        padding-top: 4vh;
    }

    .career-footer>header>h2 {
        font-size: 5vw;
        padding-bottom: 4vh;
    }

    .footer-desc>p {
        font-size: 3.5vw;
    }
}

@media only screen and (min-device-width: 410px) and (max-device-width: 450px) {
    .career-intro {
        padding-left: 3vw;
        padding-right: 3vw;
    }

    .career-intro h2 {
        font-size: 5vw;
    }

    .career-intro h4 {
        font-size: 3.5vw;
    }

    .career-intro p {
        font-size: 3.4vw;
    }

    .career-perks-leftCol {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .career-perks-heading>h2 {
        font-size: 5vw;
    }

    .career-perks-heading>p {
        font-size: 3.5vw;
        color: black;
    }

    .career-perks-heading h3 {
        font-size: 5vw;
    }

    .perks-container {
        padding: 5vh 1vh;
    }

    .perks-body {
        width: 80%;
        height: 40vh;
    }

    .perks-img {
        width: 15%;
    }

    .perks-title {
        font-size: 4vw;
    }

    .perks-desc {
        font-size: 3vw;
    }

    .career-footer {
        background-color: #f2f2f2;
        padding-top: 4vh;
    }

    .career-footer>header>h2 {
        font-size: 5vw;
        padding-bottom: 4vh;
    }

    .footer-desc>p {
        font-size: 3.5vw;
    }
}

@media only screen and (min-device-width: 390px) and (max-device-width: 400px) {
    .career-intro {
        padding-left: 3vw;
        padding-right: 3vw;
    }

    .career-intro h2 {
        font-size: 5vw;
    }

    .career-intro h4 {
        font-size: 3.5vw;
    }

    .career-intro p {
        font-size: 3.4vw;
    }

    .career-perks-leftCol {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .career-perks-heading>h2 {
        font-size: 5vw;
    }

    .career-perks-heading>p {
        font-size: 3.5vw;
        color: black;
    }

    .career-perks-heading h3 {
        font-size: 5vw;
    }

    .perks-container {
        padding: 5vh 1vh;
    }

    .perks-body {
        width: 80%;
        height: 40vh;
    }

    .perks-img {
        width: 15%;
    }

    .perks-title {
        font-size: 4vw;
    }

    .perks-desc {
        font-size: 3vw;
    }

    .career-footer {
        background-color: #f2f2f2;
        padding-top: 4vh;
    }

    .career-footer>header>h2 {
        font-size: 5vw;
        padding-bottom: 4vh;
    }

    .footer-desc>p {
        font-size: 3.5vw;
    }
}

@media only screen and (min-device-width: 370px) and (max-device-width: 380px) {
    .career-intro {
        padding-left: 3vw;
        padding-right: 3vw;
    }

    .career-intro h2 {
        font-size: 5vw;
    }

    .career-intro h4 {
        font-size: 3.5vw;
    }

    .career-intro p {
        font-size: 3.4vw;
    }

    .career-perks-leftCol {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .career-perks-heading>h2 {
        font-size: 5vw;
    }

    .career-perks-heading>p {
        font-size: 3.5vw;
        color: black;
    }

    .career-perks-heading h3 {
        font-size: 5vw;
    }

    .perks-container {
        padding: 5vh 1vh;
    }

    .perks-body {
        width: 80%;
        height: 40vh;
    }

    .perks-img {
        width: 15%;
    }

    .perks-title {
        font-size: 4vw;
    }

    .perks-desc {
        font-size: 3vw;
    }

    .career-footer {
        background-color: #f2f2f2;
        padding-top: 4vh;
    }

    .career-footer>header>h2 {
        font-size: 5vw;
        padding-bottom: 4vh;
    }

    .footer-desc>p {
        font-size: 3.5vw;
    }
}