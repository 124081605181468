.blog-landing {
  padding: 5vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  background-color: #f2f2f2;
}
.blogbody-container > .row{
  margin: 0px !important;
}
.blogs-pagination {
  background-color: #f2f2f2;
}
.blogs-pagination {
  padding: 5vw;
}
.blog-body {
  background-color: white;
  padding: 0.5vw;
  border-radius: 0px 0px 10px 10px;
}
.carousel-item > .row {
  padding: 5vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.carousel-card {
  padding: 0px;
}

.landing-img {
  width: 100%;
  height: 15vw;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 10px 10px 0px 0px;
}
.landing-title {
  width: 90%;
  height: 9vw;
  font-size: 1.3vw;
  font-family: var(--heading-font);
}
.landing-title:hover{
  cursor: pointer;
  text-decoration: underline;
}
.landing-createdon {
  font-size: 0.9vw;
  font-weight: 800;
  opacity: 0.8;
}
.landing-desc {
  font-size: 0.9vw;
  opacity: 1;
  height: 7vw;
}
.landing-category {
  font-size: 0.8vw;
  color: #1c9ce3;
}
.blogs-card {
  position: relative;
}
.card-title:active{
  cursor: pointer;
  text-decoration: underline;
}
.blog-middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.blog-text {
  background-color: black;
  color: white;
  font-size: 12px;
  padding: 12px 24px;
  cursor: pointer;
}
.blogs-card:hover .landing-img {
  opacity: 0.3;
  transform: scale(1.05);
}
.blog-container {
  border-radius: 10px;
}
.blogs-card:hover .blog-middle {
  opacity: 1;
}

.blog-body-container {
  z-index: 10;
  margin-top: -10vw;
  background-color: white;
}
.sidebar {
  border-radius: 20px;
  background-color: rgb(248, 248, 248);
}
.heading {
  font-size: 1vw;
}
.sidebar-box > h4 {
  background-color: white;
  text-align: center;
  margin: 0px 5px 30px 5px;
  padding: 5px;
  border-radius: 0px 0px 10px 10px;
}

// ///////tablet Views/////// //

@media only screen and (min-device-width: 1000px) and (max-device-width: 1400px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .landing-title {
    font-size: 1.5vw;
  }
  .landing-desc {
    font-size: 1.2vw;
  }
  .landing-category {
    font-size: 1vw;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .landing-title {
    font-size: 1.3vw;
  }
  .landing-desc {
    font-size: 1.2vw;
  }
  .landing-category {
    font-size: 1.1vw;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .landing-title {
    font-size: 1.4vw;
  }
  .landing-desc {
    font-size: 1.1vw;
  }
  .landing-category {
    font-size: 0.9vw;
  }
}

// ////////////phone View //////////// //
@media only screen and (min-device-width: 400px) and (max-device-width: 550px) and (-webkit-min-device-pixel-ratio: 3) {
  .blog-body {
    padding: 2vw;
  }
  .landing-img {
    height: 45vw;
  }
  .landing-createdon {
    font-size: 2vw;
  }
  .landing-title {
    font-size: 3.5vw;
    height: 18vw;
  }
  .landing-desc {
    font-size: 2.9vw;
    padding-bottom: 4vw;
  }
  .landing-category {
    margin-top: 8vw;
    font-size: 3vw;
    padding-left: 2vw;
  }
}
@media only screen and (min-device-width: 350px) and (max-device-width: 400px) and (-webkit-min-device-pixel-ratio: 3) {
  .blog-body {
    padding: 2vw;
  }
  .landing-img {
    height: 45vw;
  }
  .landing-createdon {
    font-size: 2.5vw;
    font-style: oblique;
  }
  .landing-title {
    font-size: 3.8vw;
    height: 18vw;
  }
  .landing-desc {
    font-size: 3.2vw;
    height: 15vw;
    padding-bottom: 4vw;
  }
  .landing-category {
    padding-left: 2vw;
    margin-top: 4vw;
    font-size: 3.5vw;
  }
}
@media only screen and (min-device-width: 410px) and (max-device-width: 450px) {
  .blog-body {
    padding: 2vw;
  }
  .landing-img {
    height: 45vw;
  }
  .landing-createdon {
    font-size: 2.5vw;
    font-style: oblique;
  }
  .landing-title {
    font-size: 3.8vw;
    height: 18vw;
  }
  .landing-desc {
    font-size: 3.2vw;
    height: 15vw;
    padding-bottom: 4vw;
  }
  .landing-category {
    padding-left: 2vw;
    margin-top: 4vw;
    font-size: 3.5vw;
  }
}
@media only screen and (min-device-width: 390px) and (max-device-width: 400px) {
  .blog-body {
    padding: 2vw;
  }
  .landing-img {
    height: 45vw;
  }
  .landing-createdon {
    font-size: 2.5vw;
    font-style: oblique;
  }
  .landing-title {
    font-size: 3.8vw;
    height: 18vw;
  }
  .landing-desc {
    font-size: 3.2vw;
    height: 15vw;
    padding-bottom: 4vw;
  }
  .landing-category {
    padding-left: 2vw;
    margin-top: 4vw;
    font-size: 3.5vw;
  }
}
@media only screen and (min-device-width: 370px) and (max-device-width: 380px) {
  .blog-body {
    padding: 2vw;
  }
  .landing-img {
    height: 45vw;
  }
  .landing-createdon {
    font-size: 2.5vw;
    font-style: oblique;
  }
  .landing-title {
    font-size: 3.8vw;
    height: 18vw;
  }
  .landing-desc {
    font-size: 3.2vw;
    height: 15vw;
    padding-bottom: 4vw;
  }
  .landing-category {
    padding-left: 2vw;
    margin-top: 4vw;
    font-size: 3.5vw;
  }
}
